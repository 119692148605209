import React from "react"
import { isPropertySignature } from "typescript"
import Page from "./template-parts/page"

type Props = {
  children: JSX.Element
}


type State = {
  error: null | string
}

class ErrorBoundary extends React.Component<Props, State> {
    
  constructor(props: Props) {
      super(props)
  
      // Define a state variable to track whether is an error or not
      this.state = {
        error: null
      }
    } 


    static getDerivedStateFromError( error: { message: string } ) {
      // Update state so the next render will show the fallback UI
      return { 
        error: error?.message 
      } 
    }


    // Sentry handles uncaught errors so we dont have to log anything here
    componentDidCatch(error:any, errorInfo:any) {}


    render() {

      if (this.state.error) {
        return <Page title="Error" type="default" error={true} loading={false}/> 
      }
  
      // Return children components in case of no error
      return this.props.children
    }
  }
  
  export default ErrorBoundary