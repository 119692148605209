import Image from 'next/image';
import Link from 'next/link';
import css from "./styles.module.scss"; 
import STKSignOut from '../sign-out';
import { User } from '@/lib/useUser';

export const Logo = () => {
    return (
      <Link href="/">
        <Image priority={true} src="/full-logo.svg" alt="Hautajaistestamentti by TESTAMENTTIKESKUS OY" width={621} height={64} style={{maxWidth: '100%'}} />
      </Link>
    ); 
}

const MobileNavigation = ( props: { authUser?: User | null } ) => {
    return (
        <div className={css.mobileNavigationWrapper}>
          <STKSignOut authUser={props.authUser} />
          <div className={css.mobileNavigation}>
              <Logo />
          </div>
        </div>

    );
    
} 

export default MobileNavigation;
