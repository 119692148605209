import css from "./styles.module.scss"; 

type Icons = 'will' | 'will_registration' | 'query' | 'query_white' | 'pdf' | 'logo';

const icons: { [key in Icons]:JSX.Element } = {
    will: (
        <svg xmlns="http://www.w3.org/2000/svg" width="63.338" height="66" viewBox="0 0 63.338 66">
            <g id="Group_32" data-name="Group 32" transform="translate(0)">
                <path id="Path_2" data-name="Path 2" d="M73.612,70.6a1.614,1.614,0,1,0,3.227,0V62.724h7.878a1.614,1.614,0,0,0,0-3.227H76.839V51.619a1.614,1.614,0,1,0-3.227,0V59.5H65.734a1.614,1.614,0,0,0,0,3.227h7.878Z" transform="translate(-64.12 -50.005)" fill="#157bfb"/>
                <path id="Path_3" data-name="Path 3" d="M206.768,190.963v-40.7H181.536a6.587,6.587,0,0,1-.011,3.2h22.041v37.5H177.709V198.4a5.15,5.15,0,0,1-5.144,5.145,5.251,5.251,0,0,1-5.245-5.246V167.651a6.585,6.585,0,0,1-3.2,0V198.3a8.457,8.457,0,0,0,8.447,8.448h38.2a7.207,7.207,0,0,0,7.2-7.2v-8.582Zm7.994,8.582a4,4,0,0,1-3.641,3.98,3.869,3.869,0,0,1-.4.02l-31.593,0a8.306,8.306,0,0,0,1.778-5.145v-4.233h33.851Z" transform="translate(-154.625 -140.745)" fill="#157bfb"/>
            </g>
        </svg>
    ),
    will_registration: (
        <svg xmlns="http://www.w3.org/2000/svg" width="65.697" height="62.105" viewBox="0 0 65.697 62.105">
            <g id="Testamenttirekisteri" transform="translate(-51.96 -90.72)">
                <path id="Path_7" data-name="Path 7" d="M286.454,480.01H263.614a1.6,1.6,0,1,0,0,3.209h22.839a1.6,1.6,0,1,0,0-3.209Z" transform="translate(-190.225 -352.548)" fill="#157bfb"/>
                <path id="Path_8" data-name="Path 8" d="M110.047,113.723V96.678h-29.9L74.041,90.72H59.57v23H51.96v34.624a4.4,4.4,0,0,0,4.3,4.479h57.1a4.4,4.4,0,0,0,4.3-4.479V113.723h-7.61ZM62.779,93.929h9.956l6.107,5.958h28v13.835H62.779Zm51.669,54.417a1.191,1.191,0,0,1-1.087,1.27h-57.1a1.191,1.191,0,0,1-1.087-1.27V116.932h59.28v31.415Z" transform="translate(0 0)" fill="#157bfb"/>
            </g>
        </svg>
    ),
    query: (
        <svg xmlns="http://www.w3.org/2000/svg" width="61.104" height="62.104" viewBox="0 0 61.104 62.104">
            <g id="Group_113" data-name="Group 113" transform="translate(-98.998 -94.071)">
                <path id="Path_4" data-name="Path 4" d="M146.7,154.944l-4.217-3.451H114.516V101.83a7.714,7.714,0,0,0-1.309-4.308h34.721a5.66,5.66,0,0,1,5.653,5.653v37.189l3.451,2.824V103.175a9.115,9.115,0,0,0-9.1-9.1H106.757A7.768,7.768,0,0,0,99,101.83v9.249h12.066v43.865Zm-44.247-47.316v-5.8a4.308,4.308,0,0,1,8.615,0v5.8Z" fill="#157bfb"/>
                <path id="Path_5" data-name="Path 5" d="M353.767,384.365l-13.177-10.847a13.97,13.97,0,1,0-11.389,5.867c.463,0,.93-.023,1.4-.07a13.935,13.935,0,0,0,7.653-3.251l10.9,9a1.732,1.732,0,0,0,.232.161l2.181,1.8a1.726,1.726,0,0,0,2.2-2.661Zm-23.509-8.484a10.521,10.521,0,0,1-2.093-20.937q.531-.053,1.054-.053a10.521,10.521,0,0,1,1.038,20.99Z" transform="translate(-194.292 -231.246)" fill="#157bfb"/>
            </g>
        </svg>
    ),
    query_white: (
        <svg xmlns="http://www.w3.org/2000/svg" width="61.104" height="62.104" viewBox="0 0 61.104 62.104">
            <g id="Group_113" data-name="Group 113" transform="translate(-98.998 -94.071)">
                <path id="Path_4" data-name="Path 4" d="M146.7,154.944l-4.217-3.451H114.516V101.83a7.714,7.714,0,0,0-1.309-4.308h34.721a5.66,5.66,0,0,1,5.653,5.653v37.189l3.451,2.824V103.175a9.115,9.115,0,0,0-9.1-9.1H106.757A7.768,7.768,0,0,0,99,101.83v9.249h12.066v43.865Zm-44.247-47.316v-5.8a4.308,4.308,0,0,1,8.615,0v5.8Z" fill="#FFF"/>
                <path id="Path_5" data-name="Path 5" d="M353.767,384.365l-13.177-10.847a13.97,13.97,0,1,0-11.389,5.867c.463,0,.93-.023,1.4-.07a13.935,13.935,0,0,0,7.653-3.251l10.9,9a1.732,1.732,0,0,0,.232.161l2.181,1.8a1.726,1.726,0,0,0,2.2-2.661Zm-23.509-8.484a10.521,10.521,0,0,1-2.093-20.937q.531-.053,1.054-.053a10.521,10.521,0,0,1,1.038,20.99Z" transform="translate(-194.292 -231.246)" fill="#FFF"/>
            </g>
        </svg>
    ),
    pdf: (
        <svg xmlns="http://www.w3.org/2000/svg" width="61.479" height="81.971" viewBox="0 0 61.479 81.971">
            <path id="file-pdf-light" d="M61.479,71.725A10.256,10.256,0,0,1,51.232,81.971H10.246A10.251,10.251,0,0,1,0,71.725H5.123a5.121,5.121,0,0,0,5.123,5.123H51.232a5.118,5.118,0,0,0,5.123-5.123ZM38.424,30.739a7.687,7.687,0,0,1-7.685-7.685V5.123H10.246a5.124,5.124,0,0,0-5.123,5.123V35.862H0V10.246A10.246,10.246,0,0,1,10.246,0H35.238a7.714,7.714,0,0,1,5.443,2.251L59.221,20.8a7.722,7.722,0,0,1,2.257,5.443v9.622H56.355V30.739ZM55.6,24.431,37.047,5.874A2.486,2.486,0,0,0,35.862,5.2V23.054a2.569,2.569,0,0,0,2.562,2.562H56.275A2.445,2.445,0,0,0,55.6,24.431ZM14.089,40.986a8.966,8.966,0,1,1,0,17.931H12.808V64.04a2.562,2.562,0,1,1-5.123,0V43.547a2.565,2.565,0,0,1,2.562-2.562Zm3.842,8.966a3.833,3.833,0,0,0-3.842-3.842H12.808v7.685h1.281A3.833,3.833,0,0,0,17.931,49.951Zm7.685-6.4a2.569,2.569,0,0,1,2.562-2.562H32.02A7.687,7.687,0,0,1,39.7,48.67V58.917A7.687,7.687,0,0,1,32.02,66.6H28.178a2.569,2.569,0,0,1-2.562-2.562Zm5.123,17.931H32.02a2.569,2.569,0,0,0,2.562-2.562V48.67a2.569,2.569,0,0,0-2.562-2.562H30.739ZM53.794,40.986a2.562,2.562,0,0,1,0,5.123H48.67v5.123h5.123a2.562,2.562,0,0,1,0,5.123H48.67V64.04a2.562,2.562,0,1,1-5.123,0V43.547a2.569,2.569,0,0,1,2.562-2.562Z" fill="#157bfb"/>
        </svg>
    ),
    logo: (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
            <path fill="#007bff" d="m24,48C10.77,48,0,37.23,0,24S10.77,0,24,0s24,10.77,24,24-10.77,24-24,24Zm0-42.53C13.78,5.47,5.47,13.78,5.47,24s8.31,18.53,18.53,18.53,18.53-8.31,18.53-18.53S34.22,5.47,24,5.47Z"/>
            <path fill="#007bff" d="m24,40.02c-8.83,0-16.02-7.19-16.02-16.02S15.17,7.98,24,7.98s16.02,7.19,16.02,16.02-7.19,16.02-16.02,16.02Zm0-27.79c-6.49,0-11.77,5.28-11.77,11.77s5.28,11.77,11.77,11.77,11.77-5.28,11.77-11.77-5.28-11.77-11.77-11.77Z"/>
        </svg>
    )
}

type Props = {
    icon: Icons
    size?: 'normal' | 'small'
}

const STKIcon = ( props: Props ) => {

    let className = css.icon;

    if ( props.size === 'small' ) {
        className += ' ' + css['icon--small'];
    } 

    return (
        <span className={className}>
            {icons[props.icon]}
        </span>
    );
};

export default STKIcon;