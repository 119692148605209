import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import css from "./styles.module.scss"; 

const STKLoader = () => {

    return(
        <div className={css.loader}>
            <FontAwesomeIcon icon={["fas", "spinner"]} spinPulse size="4x" />
        </div>
    );
}

export default STKLoader;