import Image from "next/image";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthenticationService from "@/util/authentication-service";
import SessionStorageService from "@/util/session-storage-service";
import css from "./styles.module.scss";
import ROUTES from "@/routes";
const menu = [
    {
        text: 'Meistä',
        url: ROUTES.ABOUT
    },
    {
        text: 'Tietosuojaseloste',
        url: ROUTES.PRIVACY_POLICY
    },
    {
        text: 'Evästeet',
        url: ROUTES.COOKIES
    },
    {
        text: 'Ehdot',
        url: ROUTES.TERMS
    },
    {
        text: 'Poista Hautajaistestamentti',
        url: ROUTES.WILL.MANAGE
    },
    { 
        text: 'Poista rekisteröinti',
        url: ROUTES.WILL_REGISTRATION.MANAGE
    }
];

const Footer = () => {
    
    return(
        <>
        <div className={css.footer}>  
            <div className={css.footer__inner}>
                <div>
                    <Image src="/footer-logo.svg" alt="Hautajaistestamentti by TESTAMENTTIKESKUS OY" width={392} height={45}/>
                </div>
                <div>
                    <p className="mb-4" style={{fontSize: 'var(--font-size-button)'}}><b>Yhteys</b></p>
                    <p>asiakaspalvelu@testamenttikeskus.fi</p>
                    {/* <p>+358 753 267 787</p> */}
                    <p>Maariankatu 1, 20100 Turku</p>
                </div>
                <div>
                    <ul className={css.footer__menu}>
                        { menu.map( ( item ) => <li key={item.text}><Link href={item.url}>{item.text} <FontAwesomeIcon icon={["fal", "chevron-right"]} /></Link></li> )}
                    </ul>
                </div>
            </div>
        </div>
        </>
        
    )

}

export default Footer;