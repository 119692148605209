// src/common/hooks/useAPIError/index.js
import { useContext, useEffect } from 'react';
import { ErrorContext } from '@/providers/error-provider';

const useError = () => {
  const { error, addError, removeError } = useContext(ErrorContext);
  
  useEffect( () => {
    return () => removeError();
  }, []);

  return { error, addError, removeError };
}

export default useError;