import Markdown from "markdown-to-jsx";
import {createContext, useContext, useCallback } from 'react';
import type { PageContent } from '@/util/content-service';
/*
*   Content provider used for getting text/image content used in the app
*/
const ContentContext = createContext<{ content: PageContent | null }>({content: null} );

export const useContentContext = () => useContext(ContentContext);

const ContentProvider = ( props: { content: PageContent, children: JSX.Element |JSX.Element[] } ) => {
    return(
        <ContentContext.Provider value={{ content: props.content }}>
            {props.children}
        </ContentContext.Provider>
    );
}
//<Markdown>{ content?.sections?.[props.section]?.[props.index] ? content.sections[props.section][props.index] : '' }</Markdown>


export const PageContentField = ( props: { section: string, index: number  } ) => {
    const { content } = useContentContext(); 
    return(
        <div dangerouslySetInnerHTML={ {__html: content?.sections?.[props.section]?.[props.index] ? content.sections[props.section][props.index] : '' }} />
        
    );
}

export default ContentProvider;