import '@/styles/globals.scss'
import type { AppProps } from 'next/app'
import ErrorContext from '@/providers/error-provider' 
import Script from 'next/script';
import ErrorBoundary from '@/components/error-boundry';

// Import and add needed Font awesome icons to library for easy access in app
// NOTE: Have to use require here as it fixes some hydration error stuff https://github.com/FortAwesome/Font-Awesome/issues/19348
const { library, config } = require('@fortawesome/fontawesome-svg-core');
import { faChevronLeft,faChevronRight, faPencil, faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { faPlus, faMinus, faXmark, faSignOut } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faSpinner } from '@fortawesome/pro-solid-svg-icons';
library.add( faChevronLeft, faChevronRight, faPlus, faMinus, faCheck, faPencil, faSpinner, faXmark, faSignOut, faPaperPlane ); 

// CSS doesnt aways load. this is a workaround: https://fontawesome.com/docs/web/use-with/react/use-with
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Script id="google-tag-manager" strategy="beforeInteractive">
        {
          `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.defer = true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W7H2KBNN');
          `
        }
      </Script>
      <Script id="typkit" strategy="beforeInteractive">
        {
          `
          window.addEventListener('load', function() {
            var link = document.createElement('link');
            link.rel = 'stylesheet';
            link.type = 'text/css';
            link.href = "https://use.typekit.net/ldv7zrb.css";
            document.head.appendChild(link)
          });
          `
        }
      </Script>
      <ErrorContext>
        <ErrorBoundary> 
          <Component {...pageProps} />
        </ErrorBoundary> 
      </ErrorContext>
    </>

  ); 
}
