import Link from "next/link";
import { User } from "@/lib/useUser";
import css from "./styles.module.scss"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ROUTES from "../../../routes";

type Props = {
    authUser?: User | null
}

const STKSignOut = ( props: Props ) => {

    return(
        <>
        { props.authUser &&
            <div className={css.signOut}>
                <Link href={`${ROUTES.FRONT_PAGE}?signout`}>Kirjaudu ulos <FontAwesomeIcon icon={["far", "sign-out"]}></FontAwesomeIcon></Link>
            </div> 
        }
        </>
        
    );

}

export default STKSignOut;