
const ROUTES = {
    FRONT_PAGE: '/',
    TERMS: '/ehdot',
    ABOUT: '/meista',
    PRIVACY_POLICY: '/tietosuojaseloste',
    COOKIES: '/evasteet',
    AUTH: {
        BASE: '/kirjautuminen',
        HANDLER: '/kirjautuminen/kasittelija'
    },
    WILL: {
        BASE: '/hautajaistestamentti',
        MANAGE: '/hautajaistestamentti/hallinnoi',
        PAYMENT: '/hautajaistestamentti/maksu',
        PAYMENT_CONFIRMATION: '/hautajaistestamentti/maksu/vahvistus',
        THANK_YOU: '/hautajaistestamentti/kiitos',
    },
    WILL_REGISTRATION: {
        BASE: '/testamentti-rekisteri',
        MANAGE: '/testamentti-rekisteri/hallinnoi',
        PAYMENT: '/testamentti-rekisteri/maksu',
        PAYMENT_CONFIRMATION: '/testamentti-rekisteri/maksu/vahvistus',
        THANK_YOU: '/testamentti-rekisteri/kiitos',
    },
    QUERY: {
        BASE: '/testamenttikysely',
        AUTH: '/testamenttikysely/kirjautuminen',
        PAYMENT: '/testamenttikysely/maksu',
        PAYMENT_CONFIRMATION: '/testamenttikysely/maksu/vahvistus',
        RESULTS: '/testamenttikysely/tulokset',
    }
};

export const URLS = {
    DEVELOPMENT: 'http://localhost:3000',
    PREVIEW: 'https://dev.testamenttikeskus.fi',
    PRODUCTION: 'https://www.testamenttikeskus.fi'
}


export default ROUTES;
