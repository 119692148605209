import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from 'next/link';
import styles from "./styles.module.scss"; 

type Props = {
    href?: string,
    loading?: boolean,
    children: JSX.Element | string,
    onClick?: () => void,
    isSubmit?: boolean, 
    alt?: boolean,
    simple?: boolean,
    white?: boolean,
    style?: any,
    disabled?: boolean,
    download?: boolean
}

const STKButton = ( props: Props ) => {

    if ( props.href ) {
        
        const htmlProps = {
            href: props.href,
            style: props.style,
            className: styles.button + ' ' + ( props.alt ? styles['button--alt'] : '' ) + ' ' + ( props.simple && styles['button--simple'] ) + ' ' + ( props.white && styles['button--white'] ) +  ' '
        }

        return props.download ? <a download {...htmlProps}>{props.children}</a> : <Link {...htmlProps}>{props.children}</Link>
    }
    
    return(
        <button style={props.style} 
                className={styles.button + ' ' + ( props.alt && styles['button--alt'] ) + ' ' + ( props.simple && styles['button--simple'] ) + ' ' + ( props.white && styles['button--white'] ) +  ' ' + ( props.loading ? styles['button--loading'] : '') } 
                type={ props.isSubmit ? 'submit' : 'button'} 
                onClick={props.onClick}
                disabled={props.disabled}
        >  
                    { props.loading && <FontAwesomeIcon icon={["fas", "spinner"]} spinPulse className={styles.button__spinner} /> }
                    <>{props.children}</>
                    { props.simple && <FontAwesomeIcon icon={["fal", "chevron-right"]} />}
        </button>
    );
}

export default STKButton;