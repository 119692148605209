import {createContext, useState, useCallback } from 'react';
import { captureException } from '@sentry/nextjs';

type ErrorContextError = {
  error: null | string,
  addError: any,
  removeError: any
}

export const ErrorContext = createContext<ErrorContextError>({
  error: null,
  addError: (message:string) => {},
  removeError: () => {}
});

const ErrorProvider = ( { children }: {children: JSX.Element} ) => {

  const [error, setError] = useState<null|string>(null);

  const removeError = () => {
    setError(null);
  } 

  const addError = (message: string ) => {
    captureException(message);
    setError(message); 
  } 

  const contextValue = {
    error,
    addError: useCallback((message: string) => addError(message), []),
    removeError: useCallback(() => removeError(), [])
  };

  return (
    <ErrorContext.Provider value={contextValue}>
      {children}
    </ErrorContext.Provider>
  );
}

export default ErrorProvider;