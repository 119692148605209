import Link from "next/link";
import HTMLHead from "../html-head";
import Footer from "../footer";
import STKIcon from "@/components/common/icon";
import STKButton from "@/components/common/button";
import STKSignOut from "@/components/common/sign-out";
import useError from "@/lib/useError";
import MobileNavigation, { Logo } from "@/components/common/navigation-logo";
import css from "./styles.module.scss"; 

import STKLoader from "@/components/common/loader";
import { User } from "@/lib/useUser";

type PageTypes = 'will' | 'will_registration' | 'query' | 'default'

type Props = {
    title: string,
    type: PageTypes,
    loading: boolean,
    children?: JSX.Element,
    error?: boolean,
    authUser?: User | null
};


const ErrorMessage = () => {
    return (
        <div>
            <h1 className="h4">Jotain meni vikaan.</h1>
            <p className="mb-6">Yritä uudelleen tai ota yhteyttä <a href="mailto:asiakaspalvelu@testamenttikeskus.fi">asiakaspalvelu@testamenttikeskus.fi</a>, mikäli ongelma jatkuu.</p>
            <STKButton onClick={ () => { location.reload() }}>Lataa sivu uudelleen</STKButton>
        </div>
    );
}

const HomeLink = () => {
    return (
        <Link href="/">
            <STKIcon icon="logo" />
        </Link>
    );
}

const Page = ( props: Props ) => {

    const { error } = useError();
    
    const backgroundImage: { [key in PageTypes]:string } = {
        will: '/body-bg',
        will_registration: '/body-bg',
        query: '/body-bg-query',
        default: '/body-bg'
    } 

    const headers: { [key in PageTypes]:JSX.Element } = {
        will: (
            <h1 className="h3"><STKIcon icon="will" />Hautajaistestamentti</h1>
        ),
        will_registration: (
            <h1 className="h3"><STKIcon icon="will_registration" />Testamenttirekisteri</h1>
        ),
        query: (
            <h1 className="h3"><STKIcon icon="query" />Testamenttikysely</h1>
        ),
        default: (
            <div className="mb-4" style={{width: '100%', textAlign: 'center'}}>
                <Logo />
            </div>
            
        )
    }

    const mobileHeaders: { [key in PageTypes]:JSX.Element } = {
        will: (
            <>
            <h1 className="h6"><STKIcon icon="will" />Hautajaistestamentti</h1>
            </>
        ),
        will_registration: (
            <>
            <h1 className="h6"><STKIcon icon="will_registration" />Testamentin rekisteröinti</h1>
            </>
        ),
        query: (
            <h1 className="h6"><STKIcon icon="query" />Testamenttikysely</h1>
        ),
        default: (
            <div className="mb-4" style={{width: '100%', textAlign: 'center'}}>
                <Logo /> 
            </div> 
        )
    }

    return(
        <>
            <HTMLHead title={props.title} />
            
            <STKSignOut authUser={props.authUser} />
            
            <div className={css.page} style={ {'--background-image': `url(${backgroundImage[props.type]}.webp)`, '--background-image-fallback': `url(${backgroundImage[props.type]}.png)`} as React.CSSProperties}>
                
                <> 
                    { props.type === 'default' ?
                        <MobileNavigation authUser={props.authUser} />
                        :
                        <div className={css.page__mobileHeaderWrapper}>
                            <STKSignOut authUser={props.authUser} />
                            <div className={css.page__mobileHeader}>
                                <div className={css.page__mobileHomeLink}>
                                    <HomeLink />
                                </div>
                                {mobileHeaders[props.type]}
                            </div>
                        </div>
                    }
                    
                    <div className={css.page__homeLink}>
                        <HomeLink />
                    </div>
                    <main className={css.page__main}> 
                        <div className={css.page__header}>
                            {headers[props.type]}
                        </div>
                        <div className={css.page__mainInner}>  
                            <div style={{minHeight: 'var(--content-min-height)'}}>
                                {error || props.error ?
                                    <ErrorMessage />
                                    :
                                    <>
                                        { props.loading ?
                                            <STKLoader/>
                                            :
                                            <>{props.children}</> 
                                        }
                                    </>   
                                }       
                            </div>
                        </div>
                    </main> 
                    <Footer />
                </>
            </div>    
        </>
    );

}

export default Page;